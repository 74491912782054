import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Register() {
  const [termsModalShow, setTermsModalShow] = useState(false);
  const [privacyModalShow, setPrivacyModalShow] = useState(false);

  const handleTermsModalClose = () => setTermsModalShow(false);
  const handleTermsModalShow = () => setTermsModalShow(true);

  const handlePrivacyModalClose = () => setPrivacyModalShow(false);
  const handlePrivacyModalShow = () => setPrivacyModalShow(true);

  return (
    <div className="container d-flex align-items-center justify-content-center vh-100">
      <div className="row w-100">
        <div className="col-md-8 col-lg-6">
          <h1 className="display-1 text-center mb-4">Register</h1>
          <form className="row g-3 needs-validation" noValidate>
            <div className="col-md-6">
              <label htmlFor="validationCustom01" className="form-label">
                First name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom01"
                placeholder="First Name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationCustom02" className="form-label">
                Last name
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom02"
                placeholder="Last Name"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationCustomUsername" className="form-label">
                Username
              </label>
              <div className="input-group has-validation">
                <span className="input-group-text" id="inputGroupPrepend">
                  @
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustomUsername"
                  aria-describedby="inputGroupPrepend"
                  placeholder="Username"
                  required
                />
                <div className="invalid-feedback">Please choose a username.</div>
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                placeholder="Phone Number"
                required
              />
              <div className="invalid-feedback">Please provide a valid phone number.</div>
            </div>
            <div className="col-md-6">
              <label htmlFor="validationCustom03" className="form-label">
                OTP
              </label>
              <input
                type="text"
                className="form-control"
                id="validationCustom03"
                placeholder="Enter OTP"
                required
              />
              <div className="invalid-feedback">Please provide a valid OTP.</div>
            </div>

            <div className="col-md-6">
              <label htmlFor="generateOtp" className="form-label">
                Generate OTP
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="generateOtp"
                  required
                />
                <label className="form-check-label" htmlFor="generateOtp">
                  Display OTP link
                </label>
                <div className="invalid-feedback">
                  You must enter valid OTP before submitting.
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Address"
                required
              />
              <div className="invalid-feedback">Please provide a valid address.</div>
            </div>

            <div className="col-md-6">
              <label htmlFor="pincode" className="form-label">
                Pincode
              </label>
              <input
                type="text"
                className="form-control"
                id="pincode"
                placeholder="Pincode"
                required
              />
              <div className="invalid-feedback">Please provide a valid pincode.</div>
            </div>

            <div className="col-md-6">
              <label htmlFor="placeOfDelivery" className="form-label">
                Place of Delivery
              </label>
              <input
                type="text"
                className="form-control"
                id="placeOfDelivery"
                placeholder="Place of Delivery"
                required
              />
              <div className="invalid-feedback">Please provide a valid place of delivery.</div>
            </div>

            <div className="col-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="termsAndConditions"
                  required
                />
                <label className="form-check-label" htmlFor="termsAndConditions">
                  I agree to the <a href="#" onClick={handleTermsModalShow} className="link-primary">Terms and Conditions</a> and <a href="#" onClick={handlePrivacyModalShow} className="link-primary">Privacy Policy</a>
                </label>
                <div className="invalid-feedback">
                  You must agree before submitting.
                </div>
              </div>
            </div>

            <div className="col-12 text-center">
              <button className="btn btn-primary" type="submit">
                Submit form
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Terms and Conditions Modal */}
      <Modal show={termsModalShow} onHide={handleTermsModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Here you can provide the details of the terms and conditions.
            You can write your entire terms and conditions text here.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTermsModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Privacy Policy Modal */}
      <Modal show={privacyModalShow} onHide={handlePrivacyModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Here you can provide the details of the privacy policy.
            You can write your entire privacy policy text here.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePrivacyModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

