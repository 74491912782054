import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppIcon = () => {
  const iconStyle = {
    position: 'fixed',
    top: '20px',  // Distance from the bottom of the viewport
    right: '20px',   // Distance from the right side of the viewport
    backgroundColor: '#25D366', // WhatsApp green color
    borderRadius: '50%',
    padding: '10px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Adding a shadow for better visibility
    zIndex: '1000',  // Ensures the icon is on top of other content
    cursor: 'pointer',
  };

  return (
    <a href="https://wa.me/+919791323661" target="_blank" rel="noopener noreferrer" style={iconStyle}>
      <FaWhatsapp size={30} color="white" />
    </a>
  );
};

export default WhatsAppIcon;
