// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYVCMzru8g5I9rDGaXp2RiItkiPwlPGAk",
  authDomain: "bullet-crackers.firebaseapp.com",
  projectId: "bullet-crackers",
  storageBucket: "bullet-crackers.appspot.com",
  messagingSenderId: "583791971593",
  appId: "1:583791971593:web:cf404d7aa6378e45a117d8",
  measurementId: "G-934ZGZEVG3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app); // Initialize Firestore
const auth = getAuth(app);

export { app, analytics, db ,auth }; // Export Firestore
