// Define and export your actions here
export const ADD_CART = "ADD_CART";
export const REMOVE_CART = "REMOVE_CART";
export const EMPTY_CART = "EMPTY_CART";

export const addCart = (product, quantity) => {
  return {
    type: ADD_CART,
    payload: { product, quantity },
  };
};

export const removeCart = (productId) => {
  return {
    type: REMOVE_CART,
    payload: productId,
  };
};

// If you need specific remove and delete actions, define them like this:
export const rmvCart = (productId) => {
  return {
    type: REMOVE_CART,
    payload: productId,
  };
};

export const delCart = (productId) => {
  return {
    type: REMOVE_CART,
    payload: productId,
  };
};


export const emptyCart = () => {
  return {
    type: EMPTY_CART,
    payload: [],
  };
};