import React from 'react';
import { useDispatch } from 'react-redux';
import { rmvCart, delCart, addCart } from '../../redux/action'; // Ensure this path is correct

const CartProduct = ({ product }) => {
  const dispatch = useDispatch();

  const handleRemoveFromCart = () => {
    dispatch(rmvCart(product.id));
  };

  const handleDeleteFromCart = () => {
    dispatch(delCart(product.id));
  };

  const handleAddToCart = () => {
    dispatch(addCart(product, 1)); // Assuming quantity is 1
  };

  return (
    <div className="cart-product">
      <img src={product.image} alt={product.title} />
      <div className="cart-product-details">
        <h4>{product.title}</h4>
        <p>₹{product.price}</p>
        <button onClick={handleRemoveFromCart}>Remove from Cart</button>
        <button onClick={handleDeleteFromCart}>Delete from Cart</button>
        <button onClick={handleAddToCart}>Add to Cart</button>
      </div>
    </div>
  );
};

export default CartProduct;
