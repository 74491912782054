// src/redux/reducer.js
import { ADD_CART, REMOVE_CART, EMPTY_CART } from "../action/index";

const initialState = {
  cart: [],
};

const handleCart = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CART:
      const itemIndex = state.cart.findIndex(
        (item) => item.product.id === action.payload.product.id
      );
      if (itemIndex >= 0) {
        const updatedCart = state.cart.map((item, index) =>
          index === itemIndex
            ? { ...item, quantity: action.payload.quantity }
            : item
        );
        return { ...state, cart: updatedCart };
      }
      return { ...state, cart: [...state.cart, action.payload] };

    case REMOVE_CART:
      return {
        ...state,
        cart: state.cart.filter((item) => item.product.id !== action.payload),
      };
    case EMPTY_CART:
      return {
        ...state,
        cart: [],
      };

    default:
      return state;
  }
};

export default handleCart;
