import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addOrder } from "../store/orderSlice";
import "./Checkout.css";
import { toast } from "react-hot-toast";
import emailjs from "emailjs-com";
import { addCart, emptyCart } from "../redux/action";
import { db } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

function Checkout() {
  const [billingInfo, setBillingInfo] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    transaction_id: "",
  });

  const [errors, setErrors] = useState({});
  const cartItems = useSelector((state) => state.handleCart.cart);
  const dispatch = useDispatch();

  const calculateGrandTotal = () => {
    return cartItems.reduce(
      (acc, item) => acc + item.product.price * item.quantity,
      0
    );
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setBillingInfo({ ...billingInfo, [id]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!billingInfo.firstName) errors.firstName = "First Name is required";
    if (!billingInfo.lastName) errors.lastName = "Last Name is required";
    if (!billingInfo.address) errors.address = "Address is required";
    if (!billingInfo.city) errors.city = "City is required";
    if (!billingInfo.state) errors.state = "State is required";
    if (!billingInfo.zip) errors.zip = "Zip Code is required";
    if (!billingInfo.email) {
      errors.email = "Email is required";
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(billingInfo.email)) {
      errors.email = "Invalid email format";
    }
    if (!billingInfo.transaction_id)
      errors.transaction_id = "Transaction ID is required";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    emailjs.init("InFguQKD9AfA8Sb5R");
    if (!validateForm()) {
      return;
    }

    const orderSummary = cartItems.map((item) => ({
      title: item.product.title,
      quantity: item.quantity,
      price: item.product.price * item.quantity,
    }));

    const orderSummaryMessage = cartItems
      .map((item) => {
        return `${item.product.title} (x${item.quantity}) - ₹${
          item.product.price * item.quantity
        }`;
      })
      .join("\n");

    const order = {
      billingInfo,
      orderSummary,
      total: calculateGrandTotal(),
    };

    try {
      // Save order to Firestore

      // Send email using EmailJS
      const msg = {
        to: "vsoftsolutions8813@gmail.com",
        name: billingInfo.firstName + " " + billingInfo.lastName,
        subject: "New Checkout Submission",
        email: billingInfo.email,
        message: `
          Name: ${billingInfo.firstName} ${billingInfo.lastName}
          Address: ${billingInfo.address}, ${billingInfo.city}, ${
          billingInfo.state
        }, ${billingInfo.zip}
          Email: ${billingInfo.email}
          Transaction ID: ${billingInfo.transaction_id}
          Total Amount: ₹${calculateGrandTotal()}
          
          Order Summary:
          ${orderSummaryMessage}
        `,
      };
      const orderCollectionRef = collection(db, "checkouts");
      await addDoc(orderCollectionRef, order).then((res) => {
        emailjs
          .send("service_oryibsn", "template_37ohxke", msg)
          .then((response) => {
            toast.success("Order submitted successfully!.");
            console.log("Test4");
            setBillingInfo({
              firstName: "",
              lastName: "",
              address: "",
              city: "",
              state: "",
              zip: "",
              email: "",
              transaction_id: "",
            });
            toast.success("Order email sent successfully!");
            dispatch(emptyCart());
            // window.reload
          })
          .catch((error) => {
            toast.error(
              "There was an error sending your message. Please try again."
            );
          });
      });
    } catch (error) {
      toast.error(
        "There was an error submitting your order. Please try again."
      );
      console.error("Error submitting order: ", error);
    }
  };

  return (
    <div className="container checkout-container">
      <h2 className="checkout-title">Checkout</h2>
      <div className="row m-0">
        <div className="col-md-6">
          <h4 className="mb-3">Billing Address</h4>
          <form className="needs-validation" noValidate onSubmit={handleSubmit}>
            {/* Form Fields */}
            {Object.keys(billingInfo).map((key) => (
              <div className="form-group" key={key}>
                <label htmlFor={key}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <input
                  type={key === "email" ? "email" : "text"}
                  className="form-control"
                  id={key}
                  value={billingInfo[key]}
                  onChange={handleInputChange}
                  required
                />
                {errors[key] && (
                  <div className="text-danger">{errors[key]}</div>
                )}
              </div>
            ))}
            <p className="mb-3">If you face issue means Contact Us </p>
            <button className="w-100 btn btn-primary mt-3" type="submit">
              Proceed to Payment
            </button>
          </form>
        </div>
        <div className="col-md-6">
          <h4 className="mb-3">Order Summary</h4>
          <ul className="list-group">
            {cartItems.map((item) => (
              <li
                key={item.product.id}
                className="list-group-item d-flex justify-content-between"
              >
                <span>
                  {item.product.title} (x{item.quantity})
                </span>
                <strong>₹{item.product.price * item.quantity}</strong>
              </li>
            ))}
            <li className="list-group-item d-flex justify-content-between bg-light">
              <span>Total</span>
              <strong>₹{calculateGrandTotal()}</strong>
            </li>
          </ul>
          <div>
            <img
              src="/assets/image1.jpeg"
              className="w-100 imageCart"
              alt="banner 1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
