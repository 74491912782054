import React from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

const PriceListIcon = () => {
  const iconStyle = {
    position: 'fixed',
    top: '80px',  // Adjust as needed for placement
    right: '20px', // Distance from the right side of the viewport
    backgroundColor: '#d9534f', // Bootstrap danger color (red)
    borderRadius: '50%',
    padding: '10px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Adding a shadow for better visibility
    zIndex: '1000',  // Ensures the icon is on top of other content
    cursor: 'pointer',
  };

  return (
    <>
      <a
        href="/assets/pricelist.pdf" // Replace with your actual file path
        download="Price-List.pdf"      // Optional: Specifies the default file name
        target="_blank"
        rel="noopener noreferrer"
        style={iconStyle}
        data-tip="Download Price List" // Tooltip text
      >
        <FaFilePdf size={30} color="white" />
      </a>
      <Tooltip place="left" type="dark" effect="solid" content="Download Price List" />
    </>
  );
};

export default PriceListIcon;
